import type { GetStaticProps } from "next"
import type { ImageProps } from "next/legacy/image"

import { WP_MEDIA_FIELDS, WP_SEO_FIELDS } from "@/graphql-queries"
import { AcfBlocksContainer } from "@components/acf-blocks"
import { AppHead } from "@components/app-head"
import { Header } from "@components/header"
import { Layout } from "@components/layout"
import { ACFGenericBlock, WPPage } from "@models/blocks"
import {
    AllNextLocales, AppLocalizedTranslations, WPGlobalFields, WPSeoFields
} from "@models/common"
import { getGlobalFields, getPostFields } from "@utils/acf"
import { getAppLocale } from "@utils/locale"
import { parseBlocks } from "@utils/parsers"
import { getSiteUrl } from "@utils/urls"

const GQL_HOME_QUERY = /* GraphQL */ `
  ${WP_MEDIA_FIELDS}
  ${WP_SEO_FIELDS}
  query GetHomeBlocks($id: ID!, $preview: Boolean) {
    page(id: $id, idType: DATABASE_ID, asPreview: $preview) {
      seo {
        ...WPSeoFields
      }
      featuredImage {
        node {
          ...WPMediaFields
        }
      }
      blocks {
        attributesJSON
      }
    }
  }
`

export const getStaticProps: GetStaticProps<HomeProps> = async context => {
  // fetch page and global fields
  const appLocale = getAppLocale(context.locale)
  const [globalFields, pageData] = await Promise.all([
    getGlobalFields(context),
    getPostFields<WPPage>(context, GQL_HOME_QUERY, process.env.WP_PAGEID_HOME),
  ])

  // parse blocks
  const { imageMap, acfBlocks } = await parseBlocks(pageData, appLocale)

  // build seo metadata
  pageData.page.seo.canonicalUrl = getSiteUrl(`/${appLocale}/`)
  const translations = Object.fromEntries(
    AllNextLocales.map(locale => [locale, getSiteUrl(`/${locale}/`)])
  ) as AppLocalizedTranslations

  return {
    revalidate: 60,
    props: {
      globalFields,
      imageMap,
      seo: pageData.page.seo,
      translations,
      acfBlocks,
    },
  }
}

interface HomeProps {
  globalFields: WPGlobalFields
  imageMap: Record<number, ImageProps>
  seo: WPSeoFields
  translations: AppLocalizedTranslations
  acfBlocks: ACFGenericBlock[]
}

export default function Home({ seo, translations, globalFields, imageMap, acfBlocks }: HomeProps) {
  return (
    <>
      <AppHead {...seo} translations={translations} />
      <Layout globalFields={globalFields} imageMap={imageMap}>
        <Header />
        <AcfBlocksContainer blocks={acfBlocks} />
      </Layout>
    </>
  )
}
